<template>
  <div>
    <div class="card-wrap unselect" :style="newAlertStyle" @click="clickCardNotifi(card)">
      <!-- <img v-if="card.link === '/mypage'" class="userProfile" src="/public/static/image/icon/profile_image.png" />
      <img v-else class="userProfile" :src="getImg(card.img)" /> -->
      <div class="contents-wrap">
        <div class="body4"><span v-html="card.msg"></span></div>
        <span class="body5 sub3">
          {{ datesFormat(card.created_time, 'ago') }}
        </span>
      </div>
      <img
        v-if="card.thumbnail && card.thumbnail !== ''"
        :style="imgField(getImg(card.thumbnail), isMobile ? 'mobile' : 'pc')"
        :src="getImg(card.thumbnail)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardNotification',
  components: {},
  mixins: [],
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},

  methods: {
    clickCardNotifi(item) {
      if (this.card.id === item.id) {
        if (this.card.is_read === false) {
          let url = `user/${this.user.user_id}/notification/${this.card.id}`;
          this.$axios.post(url, {}, { cancelToken: null }).then(() => {
            this.card.is_read = !this.card.is_read;
          });
        }
        if (this.card.link.indexOf('office/') > -1) {
          const a = document.createElement('a');
          a.href = this.card.link;
          a.setAttribute('target', '_blank');
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          a.parentNode.removeChild(a);
        } else {
          this.routerPush(this.card.link);
        }
        this.$emit('checkChange', false);
      }
    },
    imgField(img, device) {
      let style = {
        backgroundImage: `url(${this.image(img)})`,
        backgroundSize: 'cover',
      };
      let [w, h] = this.imgRate(42);
      style.height = `${h}px`;
      style.width = `${w}px`;
      style.objectFit = 'cover';
      if (device === 'pc') {
        style.backgroundPosition = 'center';
      } else {
        style.backgroundPosition = 'top';
      }
      return style;
    },
  },
  computed: {
    newAlertStyle() {
      if (!this.card.is_read) {
        return {
          backgroundColor: '#FFF0E5',
        };
      } else {
        return {
          backgroundColor: '#FFFFFF',
        };
      }
    },
  },
  watch: {},
};
</script>
<style scoped>
.card-wrap {
  display: grid;
  grid-template-columns: 1fr 42px;
  align-items: start;
  padding: 16px 20px;
  gap: 0 12px;
}
.userProfile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.userImg {
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.contents-wrap {
  display: flex;
  flex-direction: column;
}
</style>
